/*******************************/
/********* General CSS *********/
/*******************************/
body {
  color: #777777;
  font-weight: 500;
  background: #ffffff;
  font-family: "Quicksand", sans-serif;
  margin: 0 auto;
  padding: 0;
}

.herosection {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(./img/carousel-1.jpg);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.herosection-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.herosection-text h1 {
  text-align: center;
  color: #ffffff;
  font-size: 60px;
  font-weight: 700;
  margin-bottom: 20px;
}

.herosection-text p {
  color: #ffffff;
  text-align: center;
  font-size: 20px;
  margin-bottom: 25px;
}

.herosection-text .btn-custom {
  background-color: #2778e3;
  color: #fff;
}
.herosection-text a {
  color: #fff !important;
}

html {
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #4a4c70;
}

a {
  color: #4a4c70;
  transition: 0.3s;
}

a:hover,
a:active,
a:focus {
  color: #2778e3;
  outline: none;
  text-decoration: none;
}

.btn.btn-custom {
  padding: 12px 20px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #777777;
  border-radius: 0;
  border: 2px solid #2778e3;
  box-shadow: inset 0 0 0 0 #2778e3;
  transition: ease-out 0.3s;
  -webkit-transition: ease-out 0.3s;
  -moz-transition: ease-out 0.3s;
}

.btn.btn-custom:hover {
  color: #20212b;
  box-shadow: inset 0 0 0 30px #2778e3;
}

.btn:focus,
.form-control:focus {
  box-shadow: none;
}

.container-fluid {
  max-width: 1366px;
}

[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
  font-size: inherit;
  margin-left: 0;
}

/**********************************/
/****** Loader & Back to Top ******/
/**********************************/
#loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.3s ease-out, visibility 0s linear 0.3s;
  -o-transition: opacity 0.3s ease-out, visibility 0s linear 0.3s;
  transition: opacity 0.3s ease-out, visibility 0s linear 0.3s;
  z-index: 999;
}

#loader.show {
  -webkit-transition: opacity 0.6s ease-out, visibility 0s linear 0s;
  -o-transition: opacity 0.6s ease-out, visibility 0s linear 0s;
  transition: opacity 0.6s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

#loader .loader {
  position: relative;
  width: 45px;
  height: 45px;
  border: 5px solid #dddddd;
  border-top: 5px solid #2778e3;
  border-radius: 50%;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.back-to-top {
  position: fixed;
  display: none;
  background: #2778e3;
  width: 44px;
  height: 44px;
  text-align: center;
  line-height: 1;
  font-size: 22px;
  right: 15px;
  bottom: 15px;
  transition: background 0.5s;
  z-index: 9;
}

.back-to-top i {
  color: #4a4c70;
  padding-top: 10px;
}

.back-to-top:hover {
  background: #4a4c70;
}

.back-to-top:hover i {
  color: #2778e3;
}

/**********************************/
/********** Top Bar CSS ***********/
/**********************************/
.top-bar {
  position: absolute;
  height: 45px;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.top-bar .top-bar-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.top-bar .top-bar-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.top-bar .text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 45px;
  padding: 0 10px;
  text-align: center;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
}

.top-bar .text:last-child {
  border-right: 1px solid rgba(255, 255, 255, 0.3);
}

.top-bar .text i {
  font-size: 16px;
  color: #2778e3;
  margin-right: 5px;
}

.top-bar .text p {
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

.top-bar .social {
  display: flex;
  height: 45px;
  font-size: 0;
  justify-content: flex-end;
}

.top-bar .social a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 100%;
  font-size: 16px;
  color: #2778e3;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
}

.top-bar .social a:first-child {
  border-left: 1px solid rgba(255, 255, 255, 0.3);
}

.top-bar .social a:hover {
  color: #ffffff;
  background: #2778e3;
}

@media (min-width: 992px) {
  .top-bar {
    padding: 0 60px;
  }
}

/**********************************/
/*********** Nav Bar CSS **********/
/**********************************/
.navbar {
  position: relative;
  transition: 0.5s;
  z-index: 999;
}

.navbar.nav-sticky {
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transition: 0.5s;
}

.navbar .navbar-brand {
  margin: 0;
  color: #ffffff;
  font-size: 45px;
  line-height: 0px;
  font-weight: 700;
  letter-spacing: 5px;
  text-transform: uppercase;
}

.navbar .navbar-brand img {
  max-width: 100%;
  max-height: 40px;
}

.navbar-dark .navbar-nav .nav-link,
.navbar-dark .navbar-nav .nav-link:focus,
/* .navbar-dark .navbar-nav .nav-link:hover, */
.navbar-dark .navbar-nav .nav-link.active {
  padding: 10px 15px 8px 15px;
  color: #ffffff;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
  color: #2778e3;
  transition: none;
}

.navbar .dropdown-menu {
  margin-top: 0;
  border: 0;
  border-radius: 0;
  background: #f8f9fa;
}

@media (min-width: 992px) {
  .navbar {
    position: absolute;
    width: 100%;
    top: 45px;
    padding: 20px 60px;
    background: transparent !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    z-index: 9;
  }

  .navbar.nav-sticky {
    padding: 10px 60px;
    background: #20212b !important;
    border-bottom: none;
  }

  .page .navbar {
    background: #20212b !important;
  }

  .navbar a.nav-link {
    padding: 8px 15px;
    font-size: 16px;
    letter-spacing: 1px;
  }
}

@media (max-width: 991.98px) {
  .navbar {
    padding: 15px;
    background: #20212b !important;
  }

  .navbar a.nav-link {
    padding: 5px;
  }

  .navbar .dropdown-menu {
    box-shadow: none;
  }
}

/*******************************/
/********** Hero CSS ***********/
/*******************************/
.carousel {
  position: relative;
  width: 100%;
  min-height: 300px;
  background: #ffffff;
  margin-bottom: 45px;
}

.carousel .container-fluid {
  padding: 0;
}

.carousel .carousel-item {
  position: relative;
  width: 100%;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.carousel .carousel-img {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 300px;
  text-align: right;
  overflow: hidden;
}

.carousel .carousel-img::after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.carousel .carousel-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel .carousel-text {
  position: absolute;
  max-width: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 2;
}

.carousel .carousel-text h1 {
  text-align: center;
  color: #ffffff;
  font-size: 60px;
  font-weight: 700;
  margin-bottom: 20px;
}

.carousel .carousel-text p {
  color: #ffffff;
  text-align: center;
  font-size: 20px;
  margin-bottom: 25px;
}

.carousel .carousel-btn .btn.btn-custom {
  color: #ffffff;
}

.carousel .carousel-btn .btn.btn-custom:hover {
  color: #20212b;
}

.carousel .carousel-btn .btn:first-child {
  margin-right: 15px;
}

.carousel .owl-nav {
  position: absolute;
  width: 100%;
  height: 60px;
  top: calc(50% - 30px);
  left: 0;
  display: flex;
  justify-content: space-between;
  z-index: 9;
}

.carousel .owl-nav .owl-prev,
.carousel .owl-nav .owl-next {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2778e3;
  background: rgba(256, 256, 256, 0.2);
  font-size: 22px;
  transition: 0.3s;
}

.carousel .owl-nav .owl-prev:hover,
.carousel .owl-nav .owl-next:hover {
  color: #ffffff;
  background: #2778e3;
}

.carousel .owl-nav .owl-prev {
  margin-right: 2px;
}

.carousel .animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}

#videoModal .modal-dialog {
  position: relative;
  max-width: 800px;
  margin: 60px auto 0 auto;
}

#videoModal .modal-body {
  position: relative;
  padding: 0px;
}

#videoModal .close {
  position: absolute;
  width: 30px;
  height: 30px;
  right: 0px;
  top: -30px;
  z-index: 999;
  font-size: 30px;
  font-weight: normal;
  color: #ffffff;
  background: #000000;
  opacity: 1;
}

@media (max-width: 991.98px) {
  .carousel .carousel-text h1 {
    font-size: 35px;
  }

  .carousel .carousel-text p {
    font-size: 16px;
  }

  .carousel .carousel-text .btn {
    padding: 12px 30px;
    font-size: 15px;
    letter-spacing: 0;
  }
}

@media (max-width: 767.98px) {
  .carousel .carousel-text h1 {
    font-size: 30px;
  }

  .carousel .carousel-text .btn {
    padding: 10px 25px;
    font-size: 15px;
    letter-spacing: 0;
  }
}

@media (max-width: 575.98px) {
  .carousel .carousel-text h1 {
    font-size: 25px;
  }

  .carousel .carousel-text .btn {
    padding: 8px 20px;
    font-size: 14px;
    letter-spacing: 0;
  }
}

/*******************************/
/******* Page Header CSS *******/
/*******************************/
.page-header {
  position: relative;
  margin-bottom: 45px;
  padding: 210px 0 90px 0;
  text-align: center;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(./img/page-header.jpg);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.page-header h2 {
  position: relative;
  color: #2778e3;
  font-size: 60px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 5px;
}

.page-header h2::after {
  position: absolute;
  content: "";
  width: 100px;
  height: 2px;
  left: calc(50% - 50px);
  bottom: 0;
  background: #2778e3;
}

.page-header a {
  position: relative;
  padding: 0 12px;
  font-size: 22px;
  color: #ffffff;
}

.page-header a:hover {
  color: #2778e3;
}

.page-header a::after {
  position: absolute;
  content: "/";
  width: 8px;
  height: 8px;
  top: -2px;
  right: -7px;
  text-align: center;
  color: #ffffff;
}

.page-header a:last-child::after {
  display: none;
}

@media (max-width: 991.98px) {
  .page-header {
    padding: 60px 0;
  }

  .page-header h2 {
    font-size: 45px;
  }

  .page-header a {
    font-size: 20px;
  }
}

@media (max-width: 767.98px) {
  .page-header {
    padding: 45px 0;
  }

  .page-header h2 {
    font-size: 35px;
  }

  .page-header a {
    font-size: 18px;
  }
}

/*******************************/
/******* Section Header ********/
/*******************************/
.section-header {
  position: relative;
  width: 100%;
  max-width: 700px;
  margin: 0 auto 45px auto;
}

.section-header p {
  margin-bottom: 5px;
  position: relative;
  font-size: 22px;
  font-weight: 600;
  text-transform: capitalize;
  color: #2778e3;
}

.section-header h2 {
  margin: 0;
  font-size: 45px;
  font-weight: 700;
}

@media (max-width: 991.98px) {
  .section-header h2 {
    font-size: 45px;
  }
}

@media (max-width: 767.98px) {
  .section-header h2 {
    font-size: 40px;
  }
}

@media (max-width: 575.98px) {
  .section-header h2 {
    font-size: 35px;
  }
}

/*******************************/
/********** About CSS **********/
/*******************************/
.about {
  position: relative;
  width: 100%;
  padding: 45px 0;
}

.about .section-header {
  margin-bottom: 30px;
  margin-left: 0;
}

.about .about-img {
  width: 100%;
  /* height: 100vh; */
  object-fit: contain;
}

.about .about-tab {
  width: 100%;
}

.background-image {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(./img/donate.jpg);
  /* height: 50vh; */
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.about .about-tab .nav.nav-pills .nav-link {
  padding: 8px;
  font-weight: 600;
  background: none;
  border-radius: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  transition: none;
}

.about .about-tab .nav.nav-pills .nav-link:hover,
.about .about-tab .nav.nav-pills .nav-link.active {
  color: #2778e3;
  padding-bottom: 7px;
  border-bottom: 3px solid #2778e3;
}

.about .about-tab .tab-content {
  padding: 15px 0 0 0;
  background: transparent;
}

.about .about-tab .tab-content .container {
  padding: 0;
}

@media (max-width: 991.98px) {
  .about .section-header {
    margin-top: 30px;
  }
}

/*******************************/
/********* Service CSS *********/
/*******************************/
.service {
  position: relative;
  width: 100%;
  padding: 45px 0 15px 0;
}

.service .service-item {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 45px;
  transition: 0.3s;
}

.service .service-icon {
  position: relative;
  width: 60px;
}

.service .service-icon i {
  position: relative;
  display: block;
  color: #2778e3;
  font-size: 60px;
  line-height: 60px;
  margin-top: 5px;
}

.service .service-text {
  position: relative;
  margin-left: 20px;
  padding-left: 20px;
}

.service .service-text::before {
  position: absolute;
  content: "";
  width: 1px;
  height: calc(100% - 10px);
  top: 5px;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
}

.service .service-text::after {
  position: absolute;
  content: "";
  width: 3px;
  height: 40px;
  top: calc(50% - 20px);
  left: -1px;
  background: #2778e3;
}

.service .service-text h3 {
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 1px;
}

.service .service-text p {
  margin: 0;
}

/*******************************/
/********* Donate CSS **********/
/*******************************/
.donate {
  position: relative;
  width: 100%;
  margin: 45px 0;
  /* background: rgba(0, 0, 0, .5); */
}

.donate .donate-content {
  padding: 100px 0 100px 0;
}

.container .donate {
  margin: 90px 0;
}

.container .donate .donate-content {
  padding: 45px 30px 15px 30px;
}

.donate .donate-content .section-header {
  margin-bottom: 30px;
}

.donate .donate-content .section-header h2 {
  color: #ffffff;
}

.donate .donate-text p {
  color: #ffffff;
  font-size: 18px;
}

.donate .donate-form {
  padding: 90px 30px;
  background: #2778e3;
}

.donate .donate-form .control-group {
  margin-bottom: 15px;
}

.donate .donate-form .form-control {
  height: 60px;
  color: #ffffff;
  padding: 0 15px;
  border-radius: 0;
  border: 1px solid #ffffff;
  background: transparent;
}

.donate .donate-form .form-control::placeholder {
  color: #ffffff;
  opacity: 1;
}

.donate .donate-form .form-control:-ms-input-placeholder,
.donate .donate-form .form-control::-ms-input-placeholder {
  color: #ffffff;
}

.donate .donate-form .btn-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.donate .donate-form .btn.btn-custom {
  padding: 15px 0;
  width: 100%;
  height: 60px;
  color: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: inset 0 0 0 0 #ffffff;
}

.donate .donate-form .btn.btn-custom:hover,
.donate .donate-form .btn.btn-custom.active {
  color: #2778e3;
  border: 1px solid #ffffff;
  box-shadow: inset 0 0 0 30px #ffffff;
}

/*******************************/
/********** Causes CSS *********/
/*******************************/
.causes {
  position: relative;
  width: 100%;
  padding: 45px 0;
}

.causes .causes-carousel {
  width: calc(100% + 30px);
  margin-left: -15px;
  margin-right: -15px;
}

.causes .causes-item {
  margin: 0 15px;
  overflow: hidden;
  background: #f3f6ff;
}

.causes .causes-img {
  overflow: hidden;
}

.causes .causes-img img {
  width: 100%;
  transition: 0.3s;
}

.causes .causes-item:hover img {
  transform: scale(1.1);
}

.causes .causes-progress {
  width: 100%;
  margin-top: 32px;
  padding: 30px 30px 20px 30px;
}

.causes .progress {
  height: 10px;
  border-radius: 0;
  background: #dddddd;
  overflow: visible;
}

.causes .progress .progress-bar {
  position: relative;
  width: 0px;
  background: #2778e3;
  overflow: visible;
  transition: 2s;
}

.causes .progress-bar span {
  position: absolute;
  top: -32px;
  right: 0;
  height: 23px;
  display: inline-block;
  padding: 2px 6px;
  background: #2778e3;
  color: #20212b;
}

.causes .progress-bar span::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  top: 23px;
  left: calc(50% - 6px);
  border: 6px solid;
  border-color: #2778e3 transparent transparent transparent;
}

.causes .progress-text {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
}

.causes .progress-text p {
  margin: 0;
}

.causes .causes-text {
  padding: 0 30px;
}

.causes .causes-text h3 {
  font-size: 22px;
  font-weight: 700;
}

.causes .causes-text p {
  margin: 0;
}

.causes .causes-btn {
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
}

.causes .causes-btn .btn {
  padding: 10px 0;
  width: 50%;
}

.causes .causes-btn .btn:last-child {
  color: #20212b;
  background: #2778e3;
}

.causes .causes-btn .btn:last-child:hover {
  color: #ffffff;
}

/*******************************/
/********** Facts CSS **********/
/*******************************/
.facts {
  position: relative;
  width: 100%;
  min-height: 400px;
  margin: 45px 0;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
}

.facts .facts-item {
  display: flex;
  flex-direction: row;
  margin: 25px 0;
}

.facts .facts-item i {
  margin-top: 10px;
  font-size: 60px;
  line-height: 60px;
  color: #2778e3;
}

.facts .facts-text {
  padding-left: 20px;
}

.facts .facts-text h3 {
  position: relative;
  display: inline-block;
  color: #ffffff;
  font-size: 45px;
  font-weight: 700;
}

.facts .facts-text h3::after {
  position: absolute;
  top: 0px;
  color: #ffffff;
  font-size: 25px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.facts .facts-text h3.facts-plus::after {
  content: "\f067";
  right: -25px;
}

.facts .facts-text h3.facts-dollar::after {
  content: "\f155";
  right: -18px;
}

.facts .facts-text p {
  color: #ffffff;
  font-size: 22px;
  font-weight: 600;
  margin: 0;
}

/*******************************/
/*********** Team CSS **********/
/*******************************/
.team {
  position: relative;
  width: 100%;
  padding: 45px 0 15px 0;
}

.team .team-item {
  position: relative;
  margin-bottom: 35px;
}

.team .team-img {
  position: relative;
  overflow: hidden;
}

.team .team-img img {
  width: 100%;
  transform: scale(1.1);
  margin-bottom: -15px;
  transition: 0.3s;
}

.team .team-item:hover img {
  margin: -15px 0 0 0;
}

.team .team-text {
  position: absolute;
  width: calc(100% - 40px);
  height: 96px;
  bottom: 0;
  left: 20px;
  padding: 22px 0;
  text-align: center;
  background: #ffffff;
  transition: 0.3s;
  overflow: hidden;
}

.team .team-text h2 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}

.team .team-text p {
  margin-bottom: 20px;
}

.team .team-social {
  position: relative;
  font-size: 0;
}

.team .team-social a {
  display: inline-block;
  width: 35px;
  height: 35px;
  margin: 0 3px;
  padding: 5px 0;
  text-align: center;
  font-size: 14px;
  color: #4a4c70;
  border: 2px solid #2778e3;
  transition: 0.3s;
}

.team .team-social a:hover {
  color: #20212b;
  background: #2778e3;
}

.team .team-item:hover .team-text {
  height: 160px;
}

/*******************************/
/******* Testimonial CSS *******/
/*******************************/
.testimonial {
  position: relative;
  width: 100%;
  padding: 45px 0;
}

.testimonial .testimonials-carousel {
  position: relative;
  width: calc(100% + 30px);
  margin: 0 -15px;
}

.testimonial .testimonial-item {
  position: relative;
  width: 100%;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-bottom: 30px;
}

.testimonial .testimonial-profile {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.testimonial .testimonial-profile img {
  width: 80px;
  margin-bottom: -1px;
}

.testimonial .testimonial-name {
  padding-left: 15px;
  width: calc(100% - 95px);
}

.testimonial .testimonial-name h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
}

.testimonial .testimonial-name p {
  margin: 0;
  font-style: italic;
}

.testimonial .testimonial-text p {
  margin: 0;
}

.testimonial .owl-dots {
  margin-top: 15px;
  text-align: center;
}

.testimonial .owl-dot {
  display: inline-block;
  margin: 0 5px;
  width: 12px;
  height: 12px;
  border-radius: 0;
  background: #2778e3;
}

.testimonial .owl-dot.active {
  background: #20212b;
}

/*******************************/
/******** Volunteer CSS ********/
/*******************************/
.volunteer {
  position: relative;
  width: 100%;
  margin: 45px 0;
  background: rgba(0, 0, 0, 0.5);
}

.volunteer .volunteer-content {
  padding: 30px 0 45px 0;
}

.container .volunteer {
  margin: 90px 0;
}

.container .volunteer .volunteer-content {
  padding: 45px 30px 15px 30px;
}

.volunteer .volunteer-content .section-header {
  margin-bottom: 30px;
}

.volunteer .volunteer-content .section-header h2 {
  color: #ffffff;
}

.volunteer .volunteer-text p {
  color: #ffffff;
  font-size: 18px;
}

.volunteer .volunteer-form {
  padding: 90px 30px;
  background: #2778e3;
}

.volunteer .volunteer-form .control-group {
  margin-bottom: 15px;
}

.volunteer .volunteer-form .form-control {
  height: 60px;
  color: #ffffff;
  padding: 0 15px;
  border-radius: 0;
  border: 1px solid #ffffff;
  background: transparent;
}

.volunteer .volunteer-form textarea.form-control {
  height: 120px;
  padding: 15px;
}

.volunteer .volunteer-form .form-control::placeholder {
  color: #ffffff;
  opacity: 1;
}

.volunteer .volunteer-form .form-control:-ms-input-placeholder,
.volunteer .volunteer-form .form-control::-ms-input-placeholder {
  color: #ffffff;
}

.volunteer .volunteer-form .btn.btn-custom {
  padding: 15px 0;
  width: 100%;
  height: 60px;
  color: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: inset 0 0 0 0 #ffffff;
}

.volunteer .volunteer-form .btn.btn-custom:hover {
  color: #2778e3;
  border: 1px solid #ffffff;
  box-shadow: inset 0 0 0 30px #ffffff;
}

/*******************************/
/********** Event CSS **********/
/*******************************/
.event {
  position: relative;
  width: 100%;
  padding: 45px 0 15px 0;
}

.event .event-item {
  margin-bottom: 30px;
  background: #f3f6ff;
}

.event .event-item img {
  width: 100%;
}

.event .event-content {
  padding: 30px;
  display: flex;
}

.event .event-meta {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.event .event-meta p {
  position: relative;
  margin-bottom: 8px;
  padding-bottom: 8px;
  white-space: nowrap;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.event .event-meta p:last-child {
  border-bottom: none;
}

.event .event-meta i {
  color: #4a4c70;
  width: 25px;
}

.event .event-text {
  position: relative;
  margin-left: 20px;
  padding-left: 20px;
}

.event .event-text::before {
  position: absolute;
  content: "";
  width: 1px;
  height: calc(100% - 5px);
  top: 6px;
  left: 0;
  background: rgba(0, 0, 0, 0.15);
}

.event .event-text::after {
  position: absolute;
  content: "";
  width: 3px;
  height: 40px;
  top: calc(50% - 20px);
  left: -1px;
  background: #2778e3;
}

.event .event-text h3 {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 10px;
}

.event .event-text p {
  margin: 0;
}

.event .btn.btn-custom {
  margin-top: 20px;
  padding: 8px 30px;
}

@media (max-width: 575.98px) {
  .event .event-content {
    flex-direction: column;
  }

  .event .event-meta {
    flex-direction: row;
  }

  .event .event-meta p {
    font-size: 14px;
    padding-right: 7px;
  }

  .event .event-meta p:last-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }

  .event .event-meta i {
    width: 18px;
  }

  .event .event-text {
    margin: 0;
    padding: 0;
  }

  .event .event-text::before,
  .event .event-text::after {
    display: none;
  }
}

/*******************************/
/*********** Blog CSS **********/
/*******************************/
.blog {
  position: relative;
  width: 100%;
  padding: 45px 0 15px 0;
}

.blog .blog-item {
  margin-bottom: 30px;
  padding-bottom: 30px;
  background: #f3f6ff;
}

.blog .blog-img {
  width: 100%;
}

.blog .blog-img img {
  width: 100%;
}

.blog .blog-text {
  padding: 30px 30px 15px 30px;
}

.blog .blog-text h3 {
  font-size: 22px;
  font-weight: 700;
}

.blog .blog-text h3 a {
  color: #4a4c70;
}

.blog .blog-text h3 a:hover {
  color: #2778e3;
}

.blog .blog-text p {
  margin: 0;
}

.blog .blog-meta {
  margin: 0 30px;
  padding-top: 15px;
  display: flex;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.blog .blog-meta p {
  margin: 0;
  color: #777777;
  padding: 0 30px;
  border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.blog .blog-meta p:first-child {
  padding-left: 0;
}

.blog .blog-meta p:last-child {
  padding-right: 0;
  border: none;
}

.blog .blog-meta i {
  color: #4a4c70;
  margin-right: 8px;
}

.blog .pagination {
  margin-bottom: 15px;
}

.blog .pagination .page-link {
  color: #4a4c70;
  border-radius: 0;
  border-color: #4a4c70;
}

.blog .pagination .page-link:hover,
.blog .pagination .page-item.active .page-link {
  color: #2778e3;
  background: #4a4c70;
}

.blog .pagination .disabled .page-link {
  color: #999999;
}

/*******************************/
/********* Contact CSS *********/
/*******************************/
.contact {
  position: relative;
  width: 100%;
  padding: 45px 0;
}

.contact .contact-img {
  position: relative;
  width: 100%;
}

.contact .contact-img img {
  width: 100%;
  height: 440px;
  object-fit: cover;
}

.contact .contact-form {
  position: relative;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  margin-top: -220px;
  padding: 30px;
  background: #f3f6ff;
}

@media (max-width: 991.98px) {
  .contact .contact-form {
    max-width: 600px;
  }
}

@media (max-width: 767.98px) {
  .contact .contact-form {
    max-width: 400px;
  }
}

@media (max-width: 575.98px) {
  .contact .contact-form {
    max-width: 300px;
  }
}

.contact .contact-form input {
  padding: 15px;
  background: none;
  border-radius: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.contact .contact-form textarea {
  height: 150px;
  padding: 8px 15px;
  background: none;
  border-radius: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.contact .contact-form .btn.btn-custom {
  width: 100%;
}

.contact .help-block ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

/*******************************/
/******* Single Post CSS *******/
/*******************************/
.single {
  position: relative;
  padding: 45px 0;
}

.single .single-content {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
}

.single .single-content img {
  margin-bottom: 20px;
  width: 100%;
}

.single .single-tags {
  margin: -5px -5px 41px -5px;
  font-size: 0;
}

.single .single-tags a {
  margin: 5px;
  display: inline-block;
  padding: 7px 15px;
  font-size: 14px;
  font-weight: 500;
  color: #4a4c70;
  border: 1px solid #4a4c70;
}

.single .single-tags a:hover {
  color: #ffffff;
  background: #2778e3;
  border-color: #2778e3;
}

.single .single-bio {
  margin-bottom: 45px;
  padding: 30px;
  background: #f3f6ff;
  display: flex;
}

.single .single-bio-img {
  width: 100%;
  max-width: 100px;
}

.single .single-bio-img img {
  width: 100%;
  border: 15px solid #ffffff;
}

.single .single-bio-text {
  padding-left: 30px;
}

.single .single-bio-text h3 {
  font-size: 20px;
  font-weight: 700;
}

.single .single-bio-text p {
  margin: 0;
}

.single .single-related {
  margin-bottom: 45px;
}

.single .single-related h2 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 25px;
}

.single .related-slider {
  position: relative;
  margin: 0 -15px;
  width: calc(100% + 30px);
}

.single .related-slider .post-item {
  margin: 0 15px;
}

.single .post-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.single .post-item .post-img {
  width: 100%;
  max-width: 80px;
}

.single .post-item .post-img img {
  width: 100%;
}

.single .post-item .post-text {
  padding-left: 15px;
}

.single .post-item .post-text a {
  font-size: 16px;
  font-weight: 600;
}

.single .post-item .post-text a:hover {
  color: #2778e3;
}

.single .post-item .post-meta {
  display: flex;
  margin-top: 8px;
}

.single .post-item .post-meta p {
  display: inline-block;
  margin: 0;
  padding: 0 3px;
  font-size: 14px;
  font-weight: 500;
  font-style: italic;
}

.single .post-item .post-meta p a {
  margin-left: 5px;
  color: #999999;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
}

.single .related-slider .owl-nav {
  position: absolute;
  width: 90px;
  top: -55px;
  right: 15px;
  display: flex;
}

.single .related-slider .owl-nav .owl-prev,
.single .related-slider .owl-nav .owl-next {
  margin-left: 15px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4a4c70;
  background: #2778e3;
  font-size: 16px;
  transition: 0.3s;
}

.single .related-slider .owl-nav .owl-prev:hover,
.single .related-slider .owl-nav .owl-next:hover {
  color: #2778e3;
  background: #4a4c70;
}

.single .single-comment {
  position: relative;
  margin-bottom: 45px;
}

.single .single-comment h2 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 25px;
}

.single .comment-list {
  list-style: none;
  padding: 0;
}

.single .comment-child {
  list-style: none;
}

.single .comment-body {
  display: flex;
  margin-bottom: 30px;
}

.single .comment-img {
  width: 60px;
}

.single .comment-img img {
  width: 100%;
  border-radius: 0;
}

.single .comment-text {
  padding-left: 15px;
  width: calc(100% - 60px);
}

.single .comment-text h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 3px;
}

.single .comment-text span {
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}

.single .comment-text .btn {
  padding: 3px 10px;
  font-size: 14px;
  font-weight: 500;
  color: #4a4c70;
  background: #dddddd;
  border-radius: 0;
}

.single .comment-text .btn:hover {
  background: #2778e3;
}

.single .comment-form {
  position: relative;
}

.single .comment-form h2 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 25px;
}

.single .comment-form form {
  padding: 30px;
  background: #f3f6ff;
}

.single .comment-form form .form-group:last-child {
  margin: 0;
}

.single .comment-form input,
.single .comment-form textarea {
  border-radius: 0;
}

.single .comment-form input:focus,
.single .comment-form textarea:focus {
  border-color: #2778e3;
}

/**********************************/
/*********** Sidebar CSS **********/
/**********************************/
.sidebar {
  position: relative;
  width: 100%;
}

@media (max-width: 991.98px) {
  .sidebar {
    margin-top: 45px;
  }
}

.sidebar .sidebar-widget {
  position: relative;
  margin-bottom: 45px;
}

.sidebar .sidebar-widget .widget-title {
  position: relative;
  margin-bottom: 30px;
  padding-bottom: 5px;
  font-size: 25px;
  font-weight: 700;
}

.sidebar .sidebar-widget .widget-title::after {
  position: absolute;
  content: "";
  width: 60px;
  height: 2px;
  bottom: 0;
  left: 0;
  background: #2778e3;
}

.sidebar .sidebar-widget .search-widget {
  position: relative;
}

.sidebar .search-widget input {
  height: 50px;
  border: 1px solid #dddddd;
  border-radius: 0;
}

.sidebar .search-widget input:focus {
  box-shadow: none;
  border-color: #2778e3;
}

.sidebar .search-widget .btn {
  position: absolute;
  top: 6px;
  right: 15px;
  height: 40px;
  padding: 0;
  font-size: 25px;
  color: #2778e3;
  background: none;
  border-radius: 0;
  border: none;
  transition: 0.3s;
}

.sidebar .search-widget .btn:hover {
  color: #4a4c70;
}

.sidebar .sidebar-widget .recent-post {
  position: relative;
}

.sidebar .sidebar-widget .tab-post {
  position: relative;
}

.sidebar .tab-post .nav.nav-pills .nav-link {
  color: #4a4c70;
  background: #2778e3;
  border-radius: 0;
}

.sidebar .tab-post .nav.nav-pills .nav-link:hover,
.sidebar .tab-post .nav.nav-pills .nav-link.active {
  color: #2778e3;
  background: #4a4c70;
}

.sidebar .tab-post .tab-content {
  padding: 15px 0 0 0;
  background: transparent;
}

.sidebar .tab-post .tab-content .container {
  padding: 0;
}

.sidebar .sidebar-widget .category-widget {
  position: relative;
}

.sidebar .category-widget ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar .category-widget ul li {
  margin: 0 0 12px 22px;
}

.sidebar .category-widget ul li:last-child {
  margin-bottom: 0;
}

.sidebar .category-widget ul li a {
  display: inline-block;
  line-height: 23px;
}

.sidebar .category-widget ul li::before {
  position: absolute;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #2778e3;
  left: 1px;
}

.sidebar .category-widget ul li span {
  display: inline-block;
  float: right;
}

.sidebar .sidebar-widget .tag-widget {
  position: relative;
  margin: -5px -5px;
}

.single .tag-widget a {
  margin: 5px;
  display: inline-block;
  padding: 7px 15px;
  font-size: 14px;
  font-weight: 500;
  color: #4a4c70;
  border: 1px solid #4a4c70;
  border-radius: 0;
}

.single .tag-widget a:hover {
  color: #ffffff;
  background: #2778e3;
  border-color: #2778e3;
}

.sidebar .image-widget {
  display: block;
  width: 100%;
  overflow: hidden;
}

.sidebar .image-widget img {
  max-width: 100%;
  transition: 0.3s;
}

.sidebar .image-widget img:hover {
  transform: scale(1.1);
}

/*******************************/
/********* Footer CSS **********/
/*******************************/
.footer {
  position: relative;
  margin-top: 45px;
  padding-top: 90px;
  background: #20212b;
}

.footer .footer-contact,
.footer .footer-link,
.footer .footer-newsletter {
  position: relative;
  margin-bottom: 45px;
  color: #ffffff;
}

.footer .footer-contact h2,
.footer .footer-link h2,
.footer .footer-newsletter h2 {
  margin-bottom: 30px;
  font-size: 22px;
  font-weight: 700;
  color: #2778e3;
}

.footer .footer-link a {
  display: block;
  margin-bottom: 10px;
  color: #ffffff;
  transition: 0.3s;
}

.footer .footer-link a::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
}

.footer .footer-link a:hover {
  color: #2778e3;
  letter-spacing: 1px;
}

.footer .footer-contact p i {
  width: 25px;
}

.footer .footer-social {
  position: relative;
  margin-top: 20px;
}

.footer .footer-social a {
  width: 40px;
  height: 40px;
  padding: 6px 0;
}

.footer .footer-social a {
  margin-top: 5px;
}

.footer .footer-newsletter form {
  position: relative;
  width: 100%;
}

.footer .footer-newsletter input {
  margin-bottom: 15px;
  height: 60px;
  border: none;
  border-radius: 0;
}

.footer .footer-newsletter label {
  margin-top: 5px;
  color: #777777;
  font-size: 14px;
  letter-spacing: 1px;
}

.footer .footer-newsletter .btn.btn-custom {
  width: 100%;
  padding: 15px 0;
}

.footer .copyright {
  padding: 0 30px;
}

.footer .copyright .row {
  padding-top: 25px;
  padding-bottom: 25px;
  border-top: 1px solid rgba(256, 256, 256, 0.1);
}

.footer .copyright p {
  margin: 0;
  color: #999999;
}

.footer .copyright .col-md-6:last-child p {
  text-align: right;
}

.footer .copyright p a {
  color: #ffffff;
}

.footer .copyright p a:hover {
  color: #2778e3;
}

@media (max-width: 767.98px) {
  .footer .copyright p,
  .footer .copyright .col-md-6:last-child p {
    margin: 5px 0;
    text-align: center;
  }
}

.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
}

.custom-modal-content {
  background: white;
  border-radius: 8px;
  padding: 20px;
  position: relative;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
}

.custom-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

@media (min-width: 992px) {
  .navbar {
    margin-top: -47px;
  }
}

.sticky-top {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  z-index: 1020; /* Ensure it's above other content */
}
